import React, { forwardRef, useEffect, useState } from "react";
import Chevron from "../chevron";
import isBefore from "date-fns/isBefore";
import format from "date-fns/format";
import Carousel from "../carousel/carousel";
import { MailChimp } from "../mailchimp";

const Print = forwardRef(function Print(
  { onChevronClick, last, medias, name, text, revealOn },
  ref
) {
  const [disabled, setDisabled] = useState(isBefore(new Date(), revealOn));
  useEffect(() => {
    const interval =
      disabled &&
      window.setInterval(
        () => setDisabled(isBefore(new Date(), revealOn)),
        5000
      );
    return () => window.clearInterval(interval);
  }, [disabled, revealOn]);
  return (
    <section className="print" ref={ref}>
      <Carousel
        control
        medias={
          disabled
            ? medias
                .filter(({ type }) => type === "image")
                .map((media) => ({ ...media, hidden: true }))
            : medias
        }
      />
      <div className="text">
        <h2>{name}</h2>
        {text}
        <dl>
          <dt>Size:</dt>
          <dd>70cm x 100cm</dd>
          <dt>Limited edition of 3:</dt>
          <dd>
            There will only be 3 copies of each print at this scale. The
            addition number is hand written on each print.
          </dd>
          {!disabled && (
            <>
              <dt>Price:</dt>
              <dd>$3000 plus shipping and handling $200</dd>
            </>
          )}
        </dl>
        {disabled && (
          <div className="text">
            <p>
              <em style={{ marginTop: 15 }}>
                Will be revealed on {format(revealOn, "PPPPpppp")}
              </em>
            </p>
            <p style={{ textAlign: "center", marginBottom: 20 }}>
              Be the first to be notified when sales start
            </p>
            <MailChimp />
          </div>
        )}
        {!disabled && (
          <a
            href={`mailto:info@houseofiris.art?subject=Photo%20Print%20Purchase%20Request&body=Thank%20you%20for%20your%20order%20request%20of%20Mistress%20Iris%20silver%20gelatin%20print%20${encodeURIComponent(
              name
            )}.%20Please%20fill%20complete%20the%20information%20below.%20There%20are%20only%203%20prints%20available%20for%20each%20photo.%20We%C2%A0will%20send%20an%20invoice%20to%20the%20email%20provided%20below%C2%A0on%20a%20first%20come%20first%20serve%20basis.%20The%20fist%203%20people%C2%A0who%20reply%C2%A0to%20this%20email%20will%20receive%20an%20invoice%2C%20and%20will%20subsequently%C2%A0have%2048%20hours%20to%20complete%20the%20purchase.%20If%20there%20are%20others%20interested%2C%20any%20invoices%20that%20are%20not%20completed%20within%2048%20hours%20will%20be%20canceled%20and%20the%20next%20person%20on%20the%20wait%20list%20will%20have%20the%20opportunity%20to%20make%20the%C2%A0purchase.%C2%A0%0A%0AOrder%20information%C2%A0%0A%0APrint%20%23_%0ANumber%20of%20prints%C2%A0interested%20(3%20maximum)%3A%C2%A0%0A%0ABilling%20information%0A%0AFirst%20name%3A%0ALast%20name%3A%0AStreet%20address%3A%0ACity%3A%0AState%2FPrefecture%3A%0ACountry%3A%0AEmail%3A%0APhone%20number%20(with%20county%20code)%3A%0A%0AShipping%20information%20(if%20different%20from%20above)%0A%0AFirst%20name%3A%0ALast%20name%3A%0AStreet%20address%3A%0ACity%3A%0AState%2FPrefecture%3A%0ACountry%3A%0AEmail%3A%0APhone%20number%20(with%20county%20code)%3A`}
            target="_blank"
            rel="noreferrer noopener"
          >
            Request print
          </a>
        )}
      </div>
      <Chevron onClick={onChevronClick} up={last} />
    </section>
  );
});

export default Print;
