/* eslint-disable no-unused-vars */
import React, { useEffect, useCallback, useMemo, useRef } from "react";
import classnames from "classnames";
import ReactPlayer from "react-player";
import "./carousel.scss";
import { useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Vimeo } from "../vimeo/vimeo";
import classNames from "classnames";

const Media = ({
  type,
  url,
  selected,
  title,
  poster,
  hidden,
  above,
  ...props
}) => {
  switch (type) {
    case "vimeo":
      return <Vimeo url={url} poster={poster} {...props} />;
    case "video":
      return (
        <ReactPlayer
          controls
          src={url}
          width="100%"
          height="100%"
          config={{
            file: {
              poster: poster,
              title: title,
            },
          }}
          {...props}
        />
      );
    case "image":
    default:
      const image = getImage(props.imageQuery);
      return (
        <>
          {hidden && (
            <div
              style={{
                maxHeight: "100%",
                height: "100%",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 100,
              }}
            ></div>
          )}
          <div>
            <GatsbyImage
              image={image}
              placeholder="blurred"
              alt={title}
              objectFit="contain"
              style={{ maxHeight: "100%", filter: hidden ? "blur(2rem)" : "" }}
            />
          </div>
        </>
      );
  }
};
export default function Carousel({ medias, dots, control, className, random }) {
  const [media, setMedia] = useState(medias?.find(() => true));

  const previous = useCallback(() => {
    let index = medias.findIndex((m) => m === media);
    if (isNaN(index)) {
      setMedia(medias[0]);
    } else if (index <= 0) {
      setMedia(medias[medias.length - 1]);
    } else {
      setMedia(medias[index - 1]);
    }
  }, [medias, media]);
  const next = useCallback(() => {
    let index = medias.findIndex((m) => m === media);
    if (isNaN(index)) {
      setMedia(medias[0]);
    } else if (index >= medias.length - 1) {
      setMedia(medias[0]);
    } else {
      setMedia(medias[index + 1]);
    }
  }, [medias, media]);

  useEffect(() => {
    const keyboardControls = ({ key }) => {
      switch (key) {
        case "ArrowLeft":
          previous();
          break;
        case "ArrowRight":
          next();
          break;
        default:
          break;
      }
    };
    window.addEventListener("keydown", keyboardControls);
    return () => {
      window.removeEventListener("keydown", keyboardControls);
    };
  }, [previous, next]);
  const startMovePositionRef = useRef();
  const moveStart = useCallback((e) => {
    startMovePositionRef.current = (
      e.changedTouches ? e.changedTouches[0] : e
    ).clientX;
  }, []);
  const moveEnd = useCallback(
    (e) => {
      if (startMovePositionRef.current || startMovePositionRef.current === 0) {
        const distance =
          (e.changedTouches ? e.changedTouches[0] : e).clientX -
          startMovePositionRef.current;
        if (distance < -10) {
          next();
        } else if (distance > 10) {
          previous();
        }
        startMovePositionRef.current = null;
      }
    },
    [previous, next]
  );

  return (
    <div className={classnames("carousel", className)}>
      <div className="carousel-medias">
        {medias.map((m, i) => {
          const active = media === m;
          return (
            <div className={classNames("carousel-media", { active })} key={i}>
              <Media
                {...m}
                onMouseDown={moveStart}
                onTouchStart={moveStart}
                onMouseUp={moveEnd}
                onTouchEnd={moveEnd}
                active={active}
              />
            </div>
          );
        })}
      </div>
      {control && medias.length > 1 && (
        <nav className="carousel-controls">
          <button
            onClick={previous}
            className="previous"
            aria-label="Previous"
          />
          <button onClick={next} className="next" aria-label="Next" />
          {dots && (
            <ul>
              {medias.map((m, i) => (
                <li
                  role="button"
                  title={m.title}
                  key={i}
                  onClick={() => setMedia(m)}
                  aria-label={`Go to ${m.title}`}
                ></li>
              ))}
            </ul>
          )}
        </nav>
      )}
    </div>
  );
}
Carousel.defaultProps = {
  index: 0,
  medias: [],
  dots: false,
  control: false,
  onMediaChange: () => {},
};
