import * as React from "react";
import { graphql } from "gatsby";
import isBefore from "date-fns/isBefore";

import "./index.scss";
import Chevron from "../chevron";
import Print from "../print";
import { MailChimp } from "../mailchimp";

// markup
const IndexPage = ({ data }) => {
  const frontElementRef = React.useRef();
  const firstPrintRef = React.useRef();
  const secondPrintRef = React.useRef();
  const thirdPrintRef = React.useRef();

  const [prints] = React.useState([
    {
      name: "#1",
      text: (
        <p>
          Taken on a highly-regarded 35mm Efke film, known for its fine grain
          and deep tones.
          <br />
          Artisanally hand-printed by Csilla Szabo on heavyweight Ilford silver
          gelatin archival paper in a semi-matt finish, in an exclusive edition
          of 3.
        </p>
      ),
      medias: [
        {
          type: "image",
          title: "First print",
          imageQuery: data.firstPrint,
        },
        {
          type: "vimeo",
          title: "First print video",
          url: "https://vimeo.com/778194099/d7ab0a13df",
        },
      ],
      ref: firstPrintRef,
      onChevronClick: () =>
        secondPrintRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        }),
      revealOn: new Date("2023-12-01T21:00:00.000+01:00"),
    },
    {
      name: "#2",
      text: (
        <p>
          Shot on medium format, on the legendary German-manufacture Agfa APX
          100 with a Mamiya Super 23.
          <br />
          Artisanally hand-printed by Csilla Szabo on heavyweight Ilford silver
          gelatin archival paper in a semi-matt finish, in an exclusive edition
          of 3.
        </p>
      ),
      medias: [
        {
          type: "image",
          title: "Second print",
          imageQuery: data.secondPrint,
        },
        {
          type: "vimeo",
          title: "Second print video",
          url: "https://vimeo.com/778194135/78fee5228b",
        },
      ],
      ref: secondPrintRef,
      onChevronClick: () =>
        thirdPrintRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        }),
      revealOn: new Date("2023-12-08T21:00:00.000+01:00"),
    },
    {
      name: "#3",
      text: (
        <p>
          Also shot on medium format, on the legendary German-manufacture Agfa
          APX 100 with a Mamiya Super 23.
          <br />
          Artisanally hand-printed by Csilla Szabo on heavyweight Ilford silver
          gelatin archival paper in a semi-matt finish, in an exclusive edition
          of 3.
        </p>
      ),
      medias: [
        {
          type: "image",
          title: "Third print",
          imageQuery: data.thirdPrint,
        },
        {
          type: "vimeo",
          title: "Third print video",
          url: "https://vimeo.com/778194183/4e18fdfa23",
        },
      ],
      ref: thirdPrintRef,
      onChevronClick: () =>
        frontElementRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        }),
      revealOn: new Date("2023-12-12T21:00:00.000+01:00"),
    },
  ]);
  return isBefore(new Date(), new Date("2023-11-24T21:00:00.000+01:00")) ? (
    <main>
      <section id="front">
        <div className="text">
          <div>
            <p style={{ textAlign: "center", marginBottom: 20 }}>
              Welcome to the House of Iris online.
              <br /> Share your email to be the first notified when our doors
              open.
            </p>
            <MailChimp />
          </div>
        </div>
      </section>
    </main>
  ) : (
    <main>
      <section id="front" ref={frontElementRef}>
        <div className="text">
          <h1>Under my Second Skin</h1>
          <h2>Mistress Iris X Soren Jahan</h2>
          <p>
            “The world is replete with images of all-powerful Dominatrices-
            commanding, seductive, confident, sometimes intimidating even. The
            brutal woman who takes what she wants without any regard for your
            wishes, and maintains a cadre of subservient beings ready to serve
            at her beck and call. This is the fantasy. The reality is far more
            nuanced. In reality, it takes an exceptional degree of empathy and
            listening to map out the mind of your submissive. It takes even more
            investment and care to help your submissive grow as a person, to
            unearth their fullest potential, rather than merely regurgitate
            their fantasy back to them. You feel hurt when your sub doesn’t
            complete their task, because you’re invested. You feel burdened,
            balancing your own needs with theirs, because they think they’re
            already fulfilling your needs, and often don’t recognize the
            requisite emotional labour. Your heart sinks when you misjudge their
            limit and go too far, because you care. When photographing a
            Dominatrix, we like to present the fantasy- armoured in latex,
            leather, uniforms, depicted in all her glory and power. I take
            pleasure in putting myself on such a pedestal as well– it is as much
            my fantasy as it is theirs. Every now and then, however, it’s
            important to peel back this facade and peek into the range of
            emotions one finds themselves wading through, walking in the
            Louboutin shoes of a Dominatrix”
            <br />
            <span className="float-right italic">Mistress Iris</span>
          </p>
          <p>
            I am proud to present a collaboration with local Berlin kinkster and
            photographer{" "}
            <a
              href="https://www.sorenjahan.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              Soren Jahan
            </a>
            . These photos were shot in his studio in October 2020 on analog
            equipment and hand-developed in his darkroom.
          </p>
          <Chevron
            onClick={() =>
              firstPrintRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
              })
            }
          />
        </div>
      </section>
      {prints.map(({ ref, ...props }, index) => (
        <Print
          {...props}
          last={index === prints.length - 1}
          key={props.name}
          ref={ref}
        />
      ))}

      <div className="text" style={{ textAlign: "center", marginTop: 40 }}>
        <div>
          <p style={{ textAlign: "center", marginBottom: 20 }}>
            Subscribe to receive further announcements
          </p>
          <MailChimp />
        </div>
      </div>
    </main>
  );
};

export const query = graphql`
  query prints {
    firstPrint: file(relativePath: { eq: "prints/01.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          width: 850
          aspectRatio: 0.667
        )
      }
    }
    secondPrint: file(relativePath: { eq: "prints/02.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          width: 850
          aspectRatio: 0.667
        )
      }
    }
    thirdPrint: file(relativePath: { eq: "prints/03.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          width: 850
          aspectRatio: 0.667
        )
      }
    }
  }
`;

export default IndexPage;

export function Head() {
  return <title>House of Iris</title>;
}
