import React from "react";

export function MailChimp() {
  return (
    <div id="mc_embed_shell">
      <div id="mc_embed_signup">
        <form
          action="https://dominatrixiris.us10.list-manage.com/subscribe/post?u=4bfc96dee7dd660a4014f1dfe&amp;id=140d835dcd&amp;f_id=0044d4e5f0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
          style={{ textAlign: "center", marginBottom: 20 }}
        >
          <div id="mc_embed_signup_scroll">
            <div className="mc-field-group">
              <input
                type="email"
                name="EMAIL"
                className="required email"
                id="mce-EMAIL"
                required=""
                defaultValue=""
                placeholder="Email Address"
                style={{
                  border: "2px ridge white",
                  width: 225,
                  padding: "15px 20px",
                  background: "transparent",
                  color: "inherit",
                }}
              />
            </div>
            <div id="mce-responses" className="clear">
              <div
                className="response"
                id="mce-error-response"
                style={{ display: "none" }}
              ></div>
              <div
                className="response"
                id="mce-success-response"
                style={{ display: "none" }}
              ></div>
            </div>
            <div
              aria-hidden="true"
              style={{ position: "absolute", left: -5000 }}
            >
              <input
                type="text"
                name="b_4bfc96dee7dd660a4014f1dfe_140d835dcd"
                tabIndex="-1"
                defaultValue=""
              />
            </div>
            <div className="clear">
              <input
                type="submit"
                name="subscribe"
                id="mc-embedded-subscribe"
                value="Subscribe"
                style={{
                  boxSizing: "border-box",
                  color: "white",
                  background: "transparent",
                  border: "2px groove white",
                  borderRadius: "0",
                  textAlign: "center",
                  padding: "0.45rem 0.75rem",
                  marginTop: "15px",
                }}
              />
            </div>
          </div>
        </form>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html:
            "<script type=\"text/javascript\" src=\"https://s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js\"></script><script type=\"text/javascript\">(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';}(jQuery));var $mcj = jQuery.noConflict(true);</script>",
        }}
      />
    </div>
  );
}
