import React from "react";
import classNames from "classnames";

export default function Chevron({ onClick, up }) {
  return (
    <button
      className={classNames("chevron", { top: up, down: !up })}
      aria-label={classNames("go", { up, down: !up })}
      onClick={onClick}
    ></button>
  );
}
