import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import "./vimeo.scss";

export function Vimeo({
  url,
  poster,
  // eslint-disable-next-line no-unused-vars
  ready = () => {},
  onEnded = () => {},
  // eslint-disable-next-line no-unused-vars
  title,
  active,
  ...props
}) {
  const [play, setPlay] = useState(true);

  useEffect(() => {
    const keyboardControls = ({ key, preventDefault }) => {
      switch (key) {
        case " ":
          preventDefault();
          setPlay(!play);
          break;
        case "Escape":
          setPlay(false);
          setShowPoster(true);
          break;
        default:
          break;
      }
    };
    window.addEventListener("keydown", keyboardControls);
    return () => {
      window.removeEventListener("keydown", keyboardControls);
    };
  }, [play]);

  const wrapperRef = useRef();
  const [[width, height], setWrapperSize] = useState([]);
  return (
    <div
      className="vimeo-wrapper"
      {...props}
      style={{ width, height }}
      ref={(element) => {
        if (element) {
          const { width: wrapperWidth, height: wrapperHeight } =
            element.getBoundingClientRect();
          const [width, height] =
            wrapperWidth * 9 < wrapperHeight * 16
              ? [wrapperWidth, Math.round((wrapperWidth * 9) / 16)]
              : [Math.round((wrapperHeight * 16) / 9), wrapperHeight];
          setWrapperSize((previous) =>
            previous[0] !== width || previous[1] !== height
              ? [width, height]
              : previous
          );
        }
      }}
    >
      <ReactPlayer
        url={url}
        loop
        className="player"
        width="100%"
        height="100%"
        playing={active}
        onEnded={() => {
          onEnded();
        }}
      />
    </div>
  );
}
